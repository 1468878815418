import { useNavigate, useParams, Link } from 'react-router-dom';
import './Category.css';
import useStore from './useStore';
import { Button, Pane, Tab, Tablist } from 'evergreen-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import Ribbon from './Ribbon';
import { AgGridReact } from 'ag-grid-react';
import { PlayerInformationAtGroupLevelColumns, Score } from './types';
import Semis from './Semis';
import useBuildFinals from './useBuildFinals';
import Finals from './Finals';
import Winners from './Winners';
import { AppContext } from './AppContext';
import useBuildWinners from './useBuildWinners';
import useIsAdmin from './useIsAdmin';
import useLoadAllData from './useLoadAllData';

export type Tab = {
  roundId: number;
  name: string;
  groups: {
    id: number;
    groupName: string;
    playTill: number;
    winBy: number;
    numPlayers: number;
    players: {
      playerId: number;
      played: number;
      for: number;
      won: number;
      lost: number;
      against: number;
      difference: number;
      name: string;
    }[];
  }[];
};

const colDefs: PlayerInformationAtGroupLevelColumns = [
  {
    colId: 'name',
    field: 'name',
    headerName: 'Player',
    suppressMovable: true,
    sortable: false,
  },
  {
    field: 'played',
    headerName: 'P',
    suppressMovable: true,
    sortable: false,
  },
  {
    field: 'won',
    headerName: 'W',
    suppressMovable: true,
    sort: 'desc',
    sortable: false,
  },
  {
    field: 'lost',
    headerName: 'L',
    suppressMovable: true,
    sortable: false,
  },
  { field: 'for', headerName: 'F', suppressMovable: true, sortable: false },
  {
    field: 'against',
    headerName: 'A',
    suppressMovable: true,
    sortable: false,
  },
  {
    field: 'difference',
    headerName: 'D',
    sort: 'desc',
    suppressMovable: true,
    sortable: false,
  },
];

const Category1 = ({ idProp }: { idProp?: string }) => {
  const { id: idParams } = useParams();
  const id = idProp || idParams;

  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const tourneys = useStore((state) => state.tourneys);
  const categories = useStore((state) => state.categories);
  const rounds = useStore((state) => state.rounds);
  const groups = useStore((state) => state.groups);
  const players = useStore((state) => state.players);
  const round_groups = useStore((state) => state.roundGroups);
  const matches = useStore((state) => state.matches);
  const scores = useStore((state) => state.scores);

  const buildFinals = useBuildFinals();
  const buildWinners = useBuildWinners();

  const currentCategory = categories.find((c) => c.id === Number(id));

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [grids, setGrids] = useState({});

  const getScoresForThisPlayer = (scores: Score[], playerId: number) =>
    scores.filter((score) => score.playerId === playerId);

  const getNumberOfGames = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);
    return scoresForThisPlayer.length;
  };

  const getWinCount = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);
    let count = 0;
    scoresForThisPlayer.forEach((score) => {
      const match = matches.find((match) => match.id === score.matchId);
      if (
        match &&
        match.winner &&
        match.winner.includes(String(score.playerId!))
      ) {
        count = count + 1;
      }
    });

    return count;
  };

  const getLostCount = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);
    let count = 0;
    scoresForThisPlayer.forEach((score) => {
      const match = matches.find((match) => match.id === score.matchId);
      if (
        match &&
        match.winner &&
        !match.winner.includes(String(score.playerId!))
      ) {
        count = count + 1;
      }
    });

    return count;
  };

  const getForTotal = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);

    return scoresForThisPlayer.reduce((acc, curr) => {
      return acc + Number(curr.for);
    }, 0);
  };

  const getAgainstTotal = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);

    return scoresForThisPlayer.reduce(
      (acc, curr) => acc + Number(curr.against),
      0
    );
  };

  const getDifferenceTotal = (scores: Score[], playerId: number) => {
    const scoresForThisPlayer = getScoresForThisPlayer(scores, playerId);

    return scoresForThisPlayer.reduce(
      (acc, curr) => acc + Number(curr.difference),
      0
    );
  };

  const roundsForThisCategory = useMemo(
    () =>
      rounds
        .filter((r) => r.categoryId === Number(id))
        .sort((a, b) => b.id - a.id),
    [id, rounds]
  );

  const { loadAllData } = useLoadAllData();

  useEffect(() => {
    loadAllData();
  }, []);

  useEffect(() => {
    setSelectedIndex(roundsForThisCategory[0]?.id);
  }, [roundsForThisCategory]);

  useEffect(() => {
    if (Object.keys(grids).length === 0) {
      return;
    }

    Object.values(grids).forEach((grid) => {
      // @ts-ignore
      grid.autoSizeColumns();
      // @ts-ignore
      grid.sizeColumnsToFit();
    });
  }, [grids, selectedIndex]);

  let tabs: Tab[] = [];
  roundsForThisCategory.forEach((round) => {
    const groupsForThisRound = groups.filter((g) => g.roundId === round.id);

    const groupsWithPlayers = groupsForThisRound.map((group) => {
      const playersInGroup = round_groups
        .filter((rg) => rg.groupId === group?.id)
        .map((rg) => players.find((p) => p.id === rg.playerId));

      const scoresForThisGroup = scores.filter(
        (score) => score.groupId === group?.id
      );
      return {
        id: group?.id!,
        groupName: group?.name!,
        playTill: group?.playTill!,
        winBy: group?.winBy!,
        numPlayers: group?.numPlayers!,
        players: playersInGroup.map((p) => ({
          playerId: p?.id!,
          played: getNumberOfGames(scoresForThisGroup, p?.id!),
          for: getForTotal(scoresForThisGroup, p?.id!),
          won: getWinCount(scoresForThisGroup, p?.id!),
          lost: getLostCount(scoresForThisGroup, p?.id!),
          against: getAgainstTotal(scoresForThisGroup, p?.id!),
          difference: getDifferenceTotal(scoresForThisGroup, p?.id!),
          name: p?.name!,
        })),
      };
    });

    tabs.push({
      roundId: round.id,
      name: round.name,
      groups: groupsWithPlayers,
    });
  });

  return (
    <div className='category-page-container'>
      <div className='home-page-image-container'>
        <img src='/logo.png' height='100px'></img>
      </div>
      <Ribbon text={tourneys[0]?.name} />
      <div className='category-name'>{currentCategory?.name}</div>
      {isAdmin && (
        <div className='actionRow'>
          {
            <>
              <Button
                appearance='primary'
                onClick={() => {
                  navigate(`/newRound/${id}`);
                }}
                // disabled={!isAdmin}
              >
                <b>Create a new round</b>
              </Button>
              {currentCategory?.type === 'teams' && (
                <Button
                  appearance='primary'
                  onClick={() => buildFinals(Number(id))}
                >
                  <b>Create finals</b>
                </Button>
              )}
              {currentCategory?.type === 'individual' && (
                <Button
                  appearance='primary'
                  onClick={() => buildWinners(Number(id), [...tabs])}
                >
                  <b>Create winners</b>
                </Button>
              )}
            </>
          }
        </div>
      )}
      <Winners categoryId={Number(id)} />
      <Finals
        categoryId={Number(id)}
        forceHide={Boolean(currentCategory?.type === 'individual')}
      />
      <Semis categoryId={Number(id)} />

      <Tablist className='category-tabsList'>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.roundId}
            isSelected={selectedIndex === tab.roundId}
            onSelect={() => setSelectedIndex(tab.roundId)}
            appearance='primary'
            className={
              selectedIndex === tab.roundId
                ? 'category-tabs-selected'
                : 'category-tabs'
            }
          >
            {tab.name}
          </Tab>
        ))}
      </Tablist>
      <div className='category-tabs-container'>
        <Pane width='100%'>
          {tabs.map((tab) => (
            <Pane
              aria-hidden={tab.roundId !== selectedIndex}
              display={tab.roundId === selectedIndex ? 'block' : 'none'}
              key={tab.roundId}
              role='tabpanel'
            >
              {tab.groups.map((group) => {
                return (
                  <div className='ag-theme-quartz'>
                    <div className='groupsTitle'>
                      <div className='groupName'>{group.groupName}</div>
                      <Link
                        to={`/matches/${group.id}`}
                        className='groupMatchesTitle'
                      >
                        Show Matches
                      </Link>
                    </div>
                    <div className='groupInformationContainer'>
                      <div className='groupInfo'>
                        {group.numPlayers} players
                      </div>
                      <div className='groupInfo'>
                        {matches.filter((m) => m.groupId === group.id).length}{' '}
                        matches
                      </div>
                    </div>

                    <AgGridReact
                      rowData={group.players}
                      columnDefs={colDefs}
                      onGridReady={(params) => {
                        setGrids((prev) => ({
                          ...prev,
                          [group.id]: params.api,
                        }));
                      }}
                      gridOptions={{
                        autoSizeStrategy: {
                          type: 'fitGridWidth',
                          defaultMinWidth: 40,
                          columnLimits: [
                            {
                              colId: 'name',
                              minWidth: 100,
                            },
                          ],
                        },
                        domLayout: 'autoHeight',
                        getRowStyle: (params) => {
                          if (
                            params.node.rowIndex !== null &&
                            params.node.rowIndex <= 1
                          ) {
                            return {
                              backgroundColor: '#DCF2EA',
                              fontWeight: 500,
                            };
                          }
                        },
                      }}
                    ></AgGridReact>
                  </div>
                );
              })}
              {isAdmin && (
                <>
                  <div>show combined</div>
                  <AgGridReact
                    rowData={tab.groups.flatMap((g) => g.players)}
                    columnDefs={colDefs}
                    gridOptions={{
                      autoSizeStrategy: {
                        type: 'fitGridWidth',
                        defaultMinWidth: 40,
                        columnLimits: [
                          {
                            colId: 'name',
                            minWidth: 100,
                          },
                        ],
                      },
                      domLayout: 'autoHeight',
                      getRowStyle: (params) => {
                        if (
                          params.node.rowIndex !== null &&
                          params.node.rowIndex <= 7
                        ) {
                          return {
                            backgroundColor: '#DCF2EA',
                            fontWeight: 500,
                          };
                        }
                      },
                    }}
                  ></AgGridReact>
                </>
              )}
            </Pane>
          ))}
        </Pane>
      </div>
    </div>
  );
};

export default Category1;
